import React, { useCallback } from 'react';
import { timestampToHourString } from '../../../functions/scheduler/timeConversions';

function BlockText(props) {
  const { type, patient, className } = props;
  if (type === 'Idle') return null;
  return (
    <React.Fragment>

      <span className={className}>{type}</span>
      {/* <span style={{ width: '100%', padding: '2px', display: 'inline-block' }} /> */}
      <span className={className}>{patient}</span>
    </React.Fragment>
  );
}

export default function ScheduleRow({ xScale, schedule, closeMins, isLast = false, firstSubtlePET, firstSubtleCT, styles = {} }) {

  /**
     * formats a schedule array into a time bar
     * @param {string} type : type of schedule bar (scanner / uptake)
     * @param {array} scheduledBlocks: array of schedule objects
     */
  function parseSchedule(scheduledBlocks) {
    if (!xScale) return null;
    const blocks = [];

    let lastBlockIdx = -2;
    scheduledBlocks.forEach((block, idx) => {
      const { patient, start, end, type } = block;

      let className = styles.scheduleBlock;
      // remove left-border if there's a preceding block
      if (idx === 0 || idx - lastBlockIdx === 1) {
        className += ` ${styles.middle}`;
      }
      // remove right border if flush with schedule container
      if (end === closeMins) {
        className += ` ${styles.final}`;
      }
      if (type !== 'Idle') lastBlockIdx = idx;

      // add styles for PET and CT enabled by Subtle
      if ((type === 'PET' && patient >= firstSubtlePET)
        || (type === 'CT' && patient >= firstSubtleCT)
        || (type === 'Uptake' && patient >= firstSubtlePET)
      ) {
        className += ` ${styles.subtleScan}`;
      }

      // length set to duration of appt.
      const widthStyle = { width: `${Math.round(xScale(end - start))}px` };
      const blockClass = type === 'Idle' ? styles.idleBlock : className;
      const tooltipText = `${type}${patient === 'Idle' ? '' : ` ${patient}`}<br/>${timestampToHourString(start)} to ${timestampToHourString(end)}`;

      blocks.push(
        <BlockComponent
          key={start}
          widthStyle={widthStyle}
          className={blockClass}
          patient={patient}
          tooltipText={tooltipText}
          styles={styles}
          type={type}
        />
      );
    });

    return blocks;
  }

  if (!xScale) return null;
  return (
    <div className={isLast ? `${styles.scheduleRow} ${styles.lastRow}` : styles.scheduleRow}>
      {parseSchedule(schedule)}
    </div>
  );
}

function BlockComponent({ widthStyle, className, patient, tooltipText, styles, type }) {

  const onHover = useCallback((e) => {
    // display time on mouseover
    const tooltip = document.getElementById('tooltip')
    tooltip.dataset.visible = 'true';
    tooltip.innerHTML = tooltipText;
    tooltip.style.left = `${e.pageX + 20}px`;
    tooltip.style.top = `${e.pageY - 30}px`;
  }, [tooltipText]);

  return (
    <div
      className={className}
      style={widthStyle}
      // onMouseOver={onHover}
      onMouseMove={onHover}
      onFocus={onHover}
      onMouseOut={onExit}
      onBlur={onExit}
    >
      <BlockText
        className={styles.scheduleBlockText}
        patient={patient}
        type={type}
      />
    </div>
  );
}

function onExit() {
  // static fn to remove tooltip
  const tooltip = document.getElementById('tooltip')
  tooltip.dataset.visible = 'false';
  tooltip.style.left = '-400px';
  tooltip.style.top = '-400px';
}
