import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { fetch } from 'whatwg-fetch';
import { Form, FormField, FormButton, IfFailed, FormCheckbox, FormNumber } from 'react-pretty-forms';
import PetSchedule from './PetSchedule';
import BallLoader from '../../loaders/BallLoader2';
import Results from './Results';

const MODE = 'prod';
const ENDPOINTS = {
  prod: 'https://us-central1-subtleinsights.cloudfunctions.net/optimizeSchedule2',
  dev: 'http://localhost:5000/subtleinsights/us-central1/optimizeSchedule2',
  debug: 'http://localhost:8080',
};
const ENDPOINT = ENDPOINTS[MODE];


export default function Calculator({ styles }) {
  const form = useRef(null);
  const [schedule, setSchedule] = useState(null);
  const [showCT, setShowCT] = useState(false);

  // wake endpoint
  useEffect(() => {
    fetch(`${ENDPOINT}/wakeup`, { method: 'GET' })
      .then(body => body.text())
      .then(res => {
        console.log(res);
      });
  }, []);

  const onSubmit = useCallback((action, payload) => {
    // add a loading graphic
    setSchedule('Loading');

    return fetch(`${ENDPOINT}/demo`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then(body => {
        setSchedule(null);
        return body;
      })
      .then(body => body.json())
      .then(res => {
        setSchedule({ ...res, openAt: payload.dayStart, closeAt: payload.dayEnd });
      });
  }, []);

  const toggleCT = useCallback(e => {
    const value = e.target.checked;
    setShowCT(value);
  }, []);


  return (
    <div className={styles.calculatorContainer}>
      <h2 className={styles.title}>SubtlePET Calculator</h2>
      <Form action={ENDPOINT} cssModule={styles} ref={form} submit={onSubmit}>
        <FormNumber min={1} required type="number" name="uptakeRooms" label="# Uptake Rooms" initialValue={2} validationMessage={false} />
        <FormNumber min={1} required type="number" name="scanners" label="# of Scanners" initialValue={1} validationMessage={false} />
        <FormField type="time" rowClassName={`${styles.prettyRow} ${styles.time}`} required name="dayStart" label="Opening hour" initialValue="09:00" validationMessage={false} />
        <FormField type="time" rowClassName={`${styles.prettyRow} ${styles.time}`} required name="dayEnd" label="Closing hour" initialValue="17:00" validationMessage={false} />
        <div className={styles.formDivider} />
        <FormNumber min={1} required rowClassName={styles.ctBlockRow} data-display={showCT} name="ctTime" label="CT Block" initialValue={15} validationMessage={false} />
        <FormNumber min={1} required type="number" name="uptakeTime" label="Uptake Block" initialValue={60} validationMessage={false} />
        <FormNumber min={1} required type="number" name="scanTime" label="Scan Block" initialValue={45} validationMessage={false} />
        <FormNumber
          min={1}
          required
          className={styles.subtleInput}
          labelTextClassName={styles.subtleScanText}
          name="subtleScanTime"
          label="SubtlePET Block"
          initialValue={20}
          validationClassName={styles.subtleValidation}
          validationMessage={false}
        />
        <div className={styles.formDivider} />
        <FormCheckbox
          name="hasCT"
          label="CT Scans"
          value="hasCT"
          onChange={toggleCT}
          labelClassName={styles.ctCheckboxLabel}
          validationMessage={false}
        />
        <div className={styles.formDivider} />
        <FormButton value="Compare Throughput" />
        <IfFailed className={styles.failMessage}>
          There was a problem processing your input. Please check your input and try again.
        </IfFailed>
      </Form>
      <br />
      <CompareResults schedule={schedule} styles={styles} />
    </div>
  );
}

function CompareResults({ schedule, styles }) {
  if (!schedule) return null;
  if (schedule === 'Loading') {
    return (
      <div className={styles.scheduleComparisonContainer}>
        <BallLoader styles={styles} />
        Comparing...
      </div>
    );
  }
  const { resultsBase, resultsSubtle, openAt, closeAt } = schedule;

  const { patients: patientsBase, ctPatients: ctPatientsBase, openMins, closeMins } = resultsBase;
  const { patients: patientsSubtle, ctPatients: ctPatientsSubtle } = resultsSubtle;

  // console.log(resultsBase);
  // console.log(resultsSubtle);

  return (
    <div>
      <div className={styles.scheduleComparisonContainer}>
        {increaseBy(patientsSubtle - patientsBase, ctPatientsSubtle - ctPatientsBase)}
        <br />

        <div className={styles.scheduleHeader}>{patientsBase + ctPatientsBase} patients without SubtlePET</div>
        <PetSchedule
          styles={styles}
          schedule={resultsBase}
          openTime={openAt}
          openMins={openMins}
          closeTime={closeAt}
          closeMins={closeMins}
        />
        <div className={styles.scheduleHeader}>{patientsSubtle + ctPatientsSubtle} patients with SubtlePET</div>
        <PetSchedule
          styles={styles}
          schedule={resultsSubtle}
          openTime={openAt}
          openMins={openMins}
          closeTime={closeAt}
          closeMins={closeMins}
          firstSubtlePET={patientsBase + 1}
          firstSubtleCT={ctPatientsBase + 1}
        />
        {/* <Results styles={styles} schedule={resultsBase} opentAt={openAt}>
        <div className={styles.scheduleHeader}>{patientsBase} patients without SubtlePET</div>
      </Results>
      <Results styles={styles} schedule={resultsSubtle} opentAt={openAt}>
        <div className={styles.scheduleHeader}>{patientsSubtle} patients with SubtlePET</div>
      </Results> */}
      </div>
    </div>
  );
}

function pluralize(int) {
  return int !== 1 ? 's' : '';
}

function increaseBy(pet, ct) {
  if (ct > 0 && pet > 0) {
    return `SubtlePET will increase throughput by ${pet} PET scan${pluralize(pet)} and ${ct} CT scan${pluralize(ct)}`;
  }

  const n = ct > 0 ? ct : pet;
  const name = ct > 0 ? 'CT' : 'PET';
  return `SubtlePET will increase throughput by ${n} ${name} scan${pluralize(n)}`;
}
