import React, { useState, useEffect } from 'react';
import useContainerDimensions, { getContainerDimensions } from '../../../hooks/useContainerDimensions';
import useThrottleResize from '../../../hooks/useThrottleResize';
import useEventListener from '../../../hooks/useEventListener';
import HourGuide from './HourGuide';
import ScheduleRow from './ScheduleRow';

/**
 * Helper function to create x-scale
 * @param {number} width
 * @param {number} duration
 */
function createScale(width, duration) {
  // state accepts a fn that determines its value
  return () => n => n * width / duration;
}

/**
 * draws an hourly calendar
 * dynamically scales with size of the window
 */
export default function PetSchedule(props) {
  const {
    styles,
    schedule,
    openTime,
    openMins,
    closeTime,
    closeMins,
    firstSubtlePET = Number.POSITIVE_INFINITY,
    firstSubtleCT = Number.POSITIVE_INFINITY,
  } = props;

  // const [INITIAL_WIDTH, INITIAL_HEIGHT] = getContainerDimensions(1200, 800, 'schedule-container');

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [xScale, setScale] = useState(null);

  if (typeof window !== 'undefined') {
    useThrottleResize(100);
    useEventListener('throttledResize', () => useContainerDimensions(setWidth, setHeight, 'schedule-container'));
  }

  // get width of schedule container after it's been added to dom
  useEffect(() => {
    useContainerDimensions(setWidth, setHeight, 'schedule-container');
  }, []);

  // set x-axis scale for scheduled elements
  useEffect(() => {
    const dayDuration = closeMins - openMins;
    // if (width && width !== window.innerWidth && !xScale) 
    setScale(createScale(width, dayDuration));
  }, [width]);

  function parseScheduleRows(schedule) {
    const { scanners, rooms } = schedule;
    const tree = [];

    Object.keys(scanners).forEach(scanner => {
      const rowComponent = (
        <ScheduleRow
          key={`Scanner${scanner}`}
          styles={styles}
          xScale={xScale}
          type="Scanner"
          schedule={scanners[scanner]}
          closeMins={closeMins}
          firstSubtlePET={firstSubtlePET}
          firstSubtleCT={firstSubtleCT}
        />
      );
      tree.push(rowComponent);
    });

    Object.keys(rooms).forEach((room, idx) => {
      const rowComponent = (
        <ScheduleRow
          key={`Uptake${room}`}
          styles={styles}
          xScale={xScale}
          type="Uptake"
          schedule={rooms[room]}
          closeMins={closeMins}
          isLast={idx === Object.keys(rooms).length - 1}
          firstSubtlePET={firstSubtlePET}
        />
      );
      tree.push(rowComponent);
    });

    return tree;
  }

  return (
    <div id="schedule-container" className={styles.scheduleContainer}>
      <HourGuide
        styles={styles}
        openMins={openMins}
        closeMins={closeMins}
        xScale={xScale}
      />
      {parseScheduleRows(schedule)}
    </div>
  );
}
